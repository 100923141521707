<div *ngIf="!isDisplaySignUp">
    <ul class="signInOptions">
        <li class="or-divider" *ngIf="!IsOnlyIcon"></li>
        <li *ngIf="isFBSignIn">
            <facebook-signin FBAppKey="{{this.FBAppKeyID}}" OnlyIcon="{{this.IsOnlyIcon}}" isLinkProfile="{{this.isForLinkProfile}}"></facebook-signin>
        </li>

        <li *ngIf="isGoogleSignIn">
            <google-signin2 GoogleAppKey="{{this.GoogleAppKeyID}}" OnlyIcon="{{this.IsOnlyIcon}}" isLinkProfile="{{this.isForLinkProfile}}"></google-signin2>
        </li>
    </ul>
</div>
<div *ngIf="isDisplaySignUp">

    <ul class="signInOptions">
        <li class="or-divider"></li>

        <li *ngIf="isFBSignIn">
            <facebook-signin FBAppKey="{{this.FBAppKeyID}}" OnlyIcon="{{this.IsOnlyIcon}}" isLinkProfile="{{this.isForLinkProfile}}" isSign="true"></facebook-signin>
        </li>
        <li *ngIf="isGoogleSignIn">
            <google-signin2 GoogleAppKey="{{this.GoogleAppKeyID}}" OnlyIcon="{{this.IsOnlyIcon}}" isLinkProfile="{{this.isForLinkProfile}}" isSign="true"></google-signin2>
        </li>
    </ul>
</div>

<div style="display:none">
    <input type="checkbox" id="isSocialSignUpCHK" [(ngModel)]="this.isDisplaySignUp" /><label for="isSocialSignUpCHK">social sign-on</label>
</div>