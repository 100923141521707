import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { Observable }     from 'rxjs';

import {ApiConfig } from "../api.service";
import 'rxjs/add/operator/toPromise';


@Injectable()
export class ErrorLogService {
    constructor(private http: HttpClient) { }

    private apiURL = '/api/ErrorLogging';   
    private apiConfigInstance = ApiConfig.getInstance(); 


    public logError(error: any): Observable<any> {

        let data = JSON.stringify({errordata: error});

    
        //this.apiConfigInstance.postDataWithoutMap(this.http, this.apiURL, data).toPromise();

        this.sendToConsole(error);

        return null;
    }

    private sendToConsole(error: any): void {
        if (console && console.group && console.error) {
            console.group("Error Log Service");
            console.warn(error);
            console.groupEnd();
        }
    }
   
}
