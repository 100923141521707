<a id="google-login-button"  #loginRef>
    <i class="fa fa-google" aria-hidden="true"></i>
    <span *ngIf="this.isShowText && this.isProfile && !this.isSignUp">Link to Google</span>
    <span *ngIf="this.isShowText && !this.isProfile && !this.isSignUp">Sign In With Google</span>
    <span *ngIf="this.isShowText && this.isSignUp && !this.isProfile">Continue With Google</span>
</a>
<a id="google-login-button-cookies" style="display: none;" (click)="openPopup()" title="Third Party Cookies are disabled. Please enabled to proceed.">
    <i class="fa fa-google" aria-hidden="true"></i>
    <span *ngIf="this.isShowText && this.isProfile && !this.isSignUp">Link to Google</span>
    <span *ngIf="this.isShowText && !this.isProfile && !this.isSignUp">Sign In With Google</span>
    <span *ngIf="this.isShowText && this.isSignUp && !this.isProfile">Continue With Google</span>
</a>
<div class="modal fade" id="pop-up" tabindex="-1" role="dialog" aria-labelledby="myModalLabel_pop-up">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span class="sr-only">Close </span><i class="fa fa-times"></i></button> Cookies
      </div>
      <div class="modal-body">
        <p>
          Your browser is currently set to block cookies. To proceed using the Google option, you must enable cookies in your browser settings.
        </p>
        <ol>
          <li>At the top right, click More (3 dots) and then Settings.</li>
          <li>Click "Privacy and security" on the lefthand navigation bar.</li>
          <li>Click "Cookies and other site data" under Privacy and security.</li>
          <li>
            Under “General settings”, you may currently have selected:
            <ol type="a">
              <li>"Block third-party cookies" or</li>
              <li>"Block all cookies (not recommended)"</li>
            </ol>
          </li>
          <li>Please select the “Block third-party cookies in Incognito” radio button.</li>
          <li>Close and try logging in using the Google option again.</li>
        </ol>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Close</button>
      </div>
    </div>
  </div>
</div>
