import { Component, OnInit, Input, Attribute, ElementRef  } from '@angular/core';
import { FacebookLoginComponent } from './facebook-signin/facebook-signin';
import { GoogleLoginComponent } from './google-signin/google-signin';
import { SocialSingInService } from "./social-signin.service";


@Component({
    selector: 'social-signin',
    inputs: ['SignInVisible', 'isFBSignInVisible', 'FBAppKey', 'GoogleAppKey','IsOnlyIcon'],
    templateUrl: 'social-signin-component.html',
    providers: [SocialSingInService]
})

export class SocialSignInComponent  {

    isFBSignIn: boolean = false
    isGoogleSignIn: boolean = false
    FBAppKeyID: string = "";
    GoogleAppKeyID: string = "";
    IsOnlyIcon: boolean = false;
    applicationID: string = "0";
    isForLinkProfile: boolean = false;
    isDisplaySignUp: boolean = false;
    
    constructor(elm: ElementRef,
        private socialSingInService: SocialSingInService) {

        
        if (elm.nativeElement.getAttribute('IsOnlyIcon') == "true")
            this.IsOnlyIcon = true;

       
        
        if (elm.nativeElement.getAttribute('applicationID') != null) {
            this.applicationID = elm.nativeElement.getAttribute('applicationID');
        }

        if (elm.nativeElement.getAttribute('isSignUpDefault') != null && elm.nativeElement.getAttribute('isSignUpDefault') == "true") {
            this.isDisplaySignUp = true;
        }
       
        
        if (elm.nativeElement.getAttribute('isforlinkprofile') ==  "true") {
            this.isForLinkProfile = true;
        }

        this.socialSingInService.getSocialSignInConfig(this.applicationID).subscribe((output) => {
            this.FBAppKeyID = output.FBAPIKey
            this.GoogleAppKeyID = output.GoogleAPIKey
            this.isFBSignIn = output.FBVisibile == "true" ? true : false
            this.isGoogleSignIn = output.GooogleVisible == "true" ? true : false

            // if no key provided it will not show the app button
            if (this.FBAppKeyID == "") {
                this.isFBSignIn = false;
            }
            this.socialSingInService.setAPIKey(output.APIToken);

            if (this.GoogleAppKeyID == "") {
                this.isGoogleSignIn = false;
            }

            //console.log(this.isFBSignIn)
            //console.log(this.isGoogleSignIn)
            //console.log(this.GoogleAppKeyID)
        });



        this.updateButtonText();
      
    }

    updateButtonText() {
        var hash = window.location.hash.substr(1);

        if (hash && hash.length) {
            if (hash == 'login') {
                this.isDisplaySignUp = false;
            }
        }

    }

   
}