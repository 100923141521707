import { Injectable, Inject} from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse} from '@angular/Common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';


declare var globalapikey: any;

@Injectable()
export class ApiConfig {
    private static _instance: ApiConfig = new ApiConfig( new CookieService());
    apiBaseUrl: string;
    apiToken: string;
    
    private _apiBaseUrl: string;
    private _apiToken: string;
    private _user: any;
    private _session: any;
    private _appData: any;
    headers: HttpHeaders;
   
    constructor(private cookieService: CookieService ){
        if(ApiConfig._instance){
            throw new Error("ApiConfig class already instantiated.")
        }
        
        ApiConfig._instance = this;
    }

    public static getInstance():ApiConfig{
        return ApiConfig._instance;
    }


    /*Cookie Methods*/

    public getCookie(key: string) {
        
        return this.cookieService.get(key);
    }

    public getCookieObject(key: string) {
        

        return this.cookieService.getObject(key);
    }

    public triggerValidation() {

        var event = document.createEvent('Event');
        event.initEvent('triggerValidation', true, true);
        document.dispatchEvent(event);

    }

    public setCookie(key: string, value: string, timeoutInMinutes: number = null) {
        var options = null;
        if (timeoutInMinutes) {
            options = {
                expires: this.addMinutes(new Date(), timeoutInMinutes)
            };
        }

        this.cookieService.put(key, value, options);
    }

    public setCookieObject(key: string, value: Object, timeoutInMinutes: number = null) {
        var options = null;
        if (timeoutInMinutes) {
            options = {
                expires: this.addMinutes(new Date(), timeoutInMinutes)
            };
        }


        this.cookieService.putObject(key, value, options);
    }

    private  addMinutes(date: Date, minutes: number) {
        return new Date(date.getTime() + minutes * 60000);
    }

    public removeCookie(key: string) {

        this.cookieService.remove(key);

    }

    
    /* End Cookie Methods */

    public setApiToken(value: string):void{
        this._apiToken = value;
    }

    public getApiToken():string{
        return this._apiToken;
    }

    public setAppData(value: any): void {
        this._appData = value;
    }

    public getAppData(): any {
        return this._appData || {};
    }

    public setSession(value: any): void {
        this._session = value;
    }

    public getSession(): any {
        if (this._session) {
            return JSON.parse(this._session);
        } else {
            return null;
        }
    }

    public setApiBaseUrl(value: string): void {
        this._apiBaseUrl = value;
    }

    public getApiBaseUrl(): string {
        if (this._apiBaseUrl == undefined)
            this._apiBaseUrl = ""
        return this._apiBaseUrl;
    }
    public setUser(value: any): void {
        this._user = value;
    }

    public getUser(): any {
        if (this._user) {
            return this._user;
        } else {
            return null;
        }
    }

    public hasProduct(productId: number): boolean {
        let sessionWrapper = this.getSession();

        if (productId && sessionWrapper && sessionWrapper.systemInfo && sessionWrapper.systemInfo.productids) {
            let searchString = '|' + productId + '|';
            if (sessionWrapper.systemInfo.productids.indexOf(searchString) >= 0) {
                return true;
            }
        }
        return false;
    }
    

    public  createAuthorizationHeader(): HttpHeaders{
        var apiToken = this.getApiToken()
        if (!this.getApiToken()) {
            try {
                apiToken = globalapikey
            }
            catch (e) {
                console.log(e)
                apiToken = "";
            }
        }
        
        headers: HttpHeaders;
        if(apiToken == "")
        {
            this.headers = new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            });
        }
        else{
            this.headers = new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization" : apiToken
            });
        }
        
        let userWrapper = this.getUser();
       if (userWrapper && userWrapper.user && userWrapper.user.emulationToken) {
            this.headers.append('EmulationToken', userWrapper.user.emulationToken);
        }
        return this.headers;
    }

    public addHeaders(headers: HttpHeaders, headersToAdd: Map<string, string>) {
        headersToAdd.forEach((value, key, map) => {
            headers = headers.set(key, value);
        });
        return headers;
    }

    public getData(http: HttpClient, apiPath: string): Observable<any> {
        return http.get(this.getApiBaseUrl() + apiPath, {
            headers: this.createAuthorizationHeader()
        });
    }

    public getDataWithoutMap(http: HttpClient, apiPath: string): Observable<any> {

        return http.get(this.getApiBaseUrl() + apiPath, {
            headers: this.createAuthorizationHeader()
        });
    }

    public getDataAdditionalHeaders(http: HttpClient, apiPath: string, additionalHeaders: Map<string, string>): Observable<any> {
        let headers = this.createAuthorizationHeader()
        headers = this.addHeaders(headers, additionalHeaders);
        return http.get(this.getApiBaseUrl() + apiPath, {
            headers: headers
        });
    }

    public postData(http: HttpClient,apiPath: string, data: any): Observable<any> {

        return http.post(this.getApiBaseUrl() + apiPath, data, {
            headers: this.createAuthorizationHeader()
        });
    }

    public postDataWithoutMap(http: HttpClient, apiPath: string, data: any): Observable<any> {
        return http.post(this.getApiBaseUrl() + apiPath, data, {
            headers: this.createAuthorizationHeader()
        });
    }

    public postDataAdditionalHeaders(http: HttpClient, apiPath: string, data: any, additionalHeaders: Map<string, string>): Observable<any> {
        let headers = this.createAuthorizationHeader();
        headers = this.addHeaders(headers, additionalHeaders);
        return http.post(this.getApiBaseUrl() + apiPath, data, {
            headers: headers
        });
    }

    //HOW DOES TIHS WORK w/ my region-map component?
    private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
        error.status ? `${ error.status } - ${ error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }

    private extractData(res: Response) {        

        try {
            let body = res.json();
            return body || {};//JSON.parse(body)
        }
        catch (err) {
            return {};
        }
    }

    public createPath(iPath: string) {
        return this.getApiBaseUrl() + iPath;
    }

    // BEGIN - Vars & Methods for Student Tokens (for Professional side)
    cachedStudentTokens = new Map<number, string>();

    getStudentToken(http: HttpClient, userId: number): Observable<string> {
        if (this.cachedStudentTokens.has(userId)) {
            return of(this.cachedStudentTokens.get(userId));
        } else {
            var apiURL = '/webapi/api/StudentToken/GetStudentToken?userId=' + userId;
            return this.getData(http, apiURL).pipe(tap(output => {
                if (output ) {
                    this.cachedStudentTokens.set(userId, output);
                }
            }));
        }
    }

    public getStudentData(http: HttpClient, apiPath: string, studentId: number): Observable<any> {
        return this.getStudentToken(http, studentId).pipe(switchMap(token => {
            let studentHeader = new Map<string, string>();
            if (token ) {
                studentHeader.set("StudentToken", token);
            }
            return this.getDataAdditionalHeaders(http, apiPath, studentHeader);
        }));
    }

    public postStudentData(http: HttpClient, apiPath: string, data: any, studentId: number): Observable<any> {
        return this.getStudentToken(http, studentId).pipe(switchMap(token => {
            let studentHeader = new Map<string, string>();
            if (token && token.length) {
                studentHeader.set("StudentToken", token);
            }
            return this.postDataAdditionalHeaders(http, apiPath, data, studentHeader);
        }));
    }

    public studentTest(http: HttpClient, studentId: number): Observable<string> {
        var apiURL = '/webapi/api/CommunicationLog/StudentTest';
        return this.getStudentData(http, apiURL, studentId);
    }
    // END - Vars & Methods for Student Tokens (for Professional side)
}




@Injectable()
export class ApiService {
    // We can easily inject the API config using the DI token created when
    //  the application was bootstrapped
    constructor(
        @Inject("api.config") private apiConfig: ApiConfig
    ) {
        console.log("Injected config:", this.apiConfig);
    }
}