import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/Common/http';
import { Observable }     from 'rxjs';

import 'rxjs/add/operator/toPromise';
import { ApiConfig } from "../../core/api.service";


@Injectable()
export class SocialSingInService {
    constructor(private http: HttpClient) { }

    private apiURLPrefix = '/api/core/user/'
    private apiConfigInstance = ApiConfig.getInstance();
    
     getUser(data: any):any {
        var apiURL = this.apiURLPrefix + "getUserbyToken";
        return this.apiConfigInstance.postData(this.http, apiURL,data );
    }

    getSocialSignInConfig(ID: any):any {
        var apiURL = this.apiURLPrefix + "getSocialSignInConfig/" + ID ;
        return this.apiConfigInstance.getData(this.http, apiURL);
     }

    updateSocialSignIn(data: any): any {
        var apiURL = this.apiURLPrefix + "updateSocialSign/" ;
        return this.apiConfigInstance.postData(this.http, apiURL, data);
    }

    setAPIKey(APIkey: string): void {
        this.apiConfigInstance.setApiToken(APIkey);
    }
}

export class SignInUser {
    public imageurl: string;
    public userid: string;    
    public email: string;
    public name: string;
    public firstname: string;
    public lastname: string;
    public token: string;
    public tokentype: string;
    public password: string;
    public isemailalreadyexist: string;
    public usertype: string;
}