import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { SocialSingInService, SignInUser } from "../social-signin.service";
declare var $: any;
declare const FB: any;

@Component({
    selector: 'facebook-signin',
    templateUrl: 'facebook-signin.html',
    providers: [SocialSingInService]
})

export class FacebookLoginComponent implements OnInit {
    tokentype: string = "FB"
    isLoading: boolean = true;
    connected: boolean = true
    FBAuthResponse : any;
    isShowText: boolean = true;
    isProfile: boolean = false;
    isSignUp: boolean = false;

    @Input() FBAppKey: string;
    @Input() OnlyIcon: string;
    @Input() isLinkProfile: string;
    @Input() isSign: string;

    constructor( private socialSingInService: SocialSingInService) {
        this.isLoading = true
    }

    onFacebookLoginClick() {
        this.isLoading = false
        FB.login(resp => this.statusChangeCallback(resp), { scope: 'public_profile,email' })
    }

    callUserAPI(response) {
     
        let signInUser: SignInUser = new SignInUser();
        signInUser.token = response.id;
        signInUser.userid = "FB^" + response.id;
        signInUser.tokentype = this.tokentype;
        signInUser.name = response.name;
        signInUser.firstname = response.first_name;
        signInUser.lastname = response.last_name;
        signInUser.email = response.email;

        if (this.isProfile) {
            this.socialSingInService.updateSocialSignIn(JSON.stringify({ signInUser })).subscribe((output) => {
                $('html').trigger('AccountLinkedWithSocialSignIn', "Facebook");
            });
        }
        else {
            this.socialSingInService.getUser(JSON.stringify({ signInUser })).subscribe((output) => {
                if (output.UserId == 0) {
                    signInUser.password = output.Password
                    signInUser.isemailalreadyexist = output.IsEmailAlreadyExist
                    signInUser.usertype = output.UserType
                    $('html').trigger('createAccount', signInUser);
                }
                else
                    $('html').trigger('signInAccount', output);
            });
        }
    }
    statusChangeCallback(resp) {
        this.FBAuthResponse = resp;
        if (resp.status === 'connected') {
            FB.api('/me?fields=id,name,first_name,last_name,email', resp => this.callUserAPI(resp));
        } else if (resp.status === 'not_authorized') {
            // handle
        } else {
            // handled
        }
    }
    ngOnInit() {
        FB.init({
            appId: this.FBAppKey,
            cookie: false,  // enable cookies to allow the server to access// the session
            xfbml: true,  // parse social plugins on this page
            version: 'v2.10',// use graph api version v2.5
        });
        FB.AppEvents.logPageView();
        //if (this.OnlyIcon == "true")
          //  this.isShowText = false
        if (this.isLinkProfile == "true")
            this.isProfile = true
        if (this.isSign == "true")
            this.isSignUp = true
    }
};



