import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { SocialSignInModule } from "./social-signin-module"

import { environment } from '../../../environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(SocialSignInModule)
  .catch(err => console.error(err));


