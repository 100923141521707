import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { createCustomElement } from '@angular/elements';

import { FacebookLoginComponent } from './facebook-signin/facebook-signin';
import { GoogleLoginComponent } from './google-signin/google-signin';
import { SocialSignInComponent } from './social-signin-component';
import { GoogleSignIn2Component } from './google-signin-2/google-signin2';

import { ErrorLogService } from "../../core/error-log/errorlog.service";
import { LOGGING_ERROR_HANDLER_PROVIDERS } from "../../core/error-log/errorlog.handler";
import { LOGGING_ERROR_HANDLER_OPTIONS } from "../../core/error-log/errorlog.handler";
import { ErrorLogModule } from "../../core/error-log/errorlog.module";

 

const CLIENT_ID = '226938967853-78hbm6u9n6uolrhi3urqnkcd12u07kk8.apps.googleusercontent.com';

@NgModule({
    declarations: [
        GoogleLoginComponent,
        FacebookLoginComponent,
        SocialSignInComponent,
        GoogleSignIn2Component
        
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ErrorLogModule        
    ],
    providers: [{
        provide: LocationStrategy, useClass: HashLocationStrategy
    },
        ErrorLogService,
       
    ],
    entryComponents: [SocialSignInComponent]
})

export class SocialSignInModule {
  constructor(private injector: Injector) { }

  ngDoBootstrap() {
    const el = createCustomElement(SocialSignInComponent, { injector: this.injector });
    if (!customElements.get('social-signin-element')) {
      customElements.define('social-signin-element', el);
    }
  }
}
