import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ignoreElements } from 'rxjs/operators';

import { SocialSingInService, SignInUser } from "../social-signin.service";

declare var $: any;

@Component({
  selector: 'google-signin2',
  templateUrl: 'google-signin2.html',
  providers: []

})

export class GoogleSignIn2Component implements OnInit {

  tokentype: string = "Google"
  isLoading: boolean = true;
  isShowText: boolean = true;
  isProfile: boolean = false;
  isSignUp: boolean = false;
  
  @Input() GoogleAppKey: string;
  @Input() OnlyIcon: string;
  @Input() isLinkProfile: string;
  @Input() isSign: string;

  auth2: any;

  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;

  constructor(private socialSingInService: SocialSingInService) {
    //console.log("TEST")
  }

  ngOnInit() {
    this.isLoading = true
  try {
 
    if(!navigator.cookieEnabled)
    {
      document.getElementById("google-login-button").style.display = "none";
      document.getElementById("google-login-button-cookies").style.display = "block";
    }

    window.addEventListener('error', function(event) {
      if(event.error.error == "idpiframe_initialization_failed" 
        && event.error.details == "Cookies are not enabled in current environment." ){

        document.getElementById("google-login-button").style.display = "none";
        document.getElementById("google-login-button-cookies").style.display = "block";
      }
    });

    this.googleAuthSDK();
    this.isLoading = false
    
    if (this.isLinkProfile == "true")
      this.isProfile = true

    if (this.isSign == "true")
      this.isSignUp = true

 
    }
    catch(ex){console.log(ex)}

  }

  openPopup(){
    $('#pop-up').modal('show');
  }
  closePopup(){
    $('#pop-up').modal('hide');
  }

  callLogin() {

    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleAuthUser: any) => {

        //Print profile details in the console logs

        let profile = googleAuthUser.getBasicProfile();
        let signInUser : SignInUser = new SignInUser();
          let name = profile.getName() ;
          let nameA: any = name.split(" "); 
 
          signInUser.tokentype = this.tokentype;
          signInUser.token = profile.getId();
          signInUser.imageurl = profile.getImageUrl();
          signInUser.name = name;
          signInUser.email = profile.getEmail();
          signInUser.userid = "GO^" + profile.getId();
          signInUser.firstname = nameA[0] ;
          signInUser.lastname = nameA.length > 1 ? nameA[1]: "";;

        if (!this.isLoading) {
          if (this.isProfile) {
              this.socialSingInService.updateSocialSignIn(JSON.stringify({ signInUser })).subscribe((output) => {
                  $('html').trigger('AccountLinkedWithSocialSignIn', this.tokentype);
              });
          }
          else{
              this.socialSingInService.getUser(JSON.stringify({ signInUser })).subscribe((output) => {
                  if (output.UserId == 0) {
                      signInUser.password = output.Password
                      signInUser.isemailalreadyexist = output.IsEmailAlreadyExist
                      signInUser.usertype = output.UserType
                      $('html').trigger('createAccount', signInUser);
                  }
                  else
                      $('html').trigger('signInAccount', output);
                  });
              }
            }

      }, (error: any) => {
        alert(JSON.stringify(error, undefined, 2));
      });

    }

  googleAuthSDK() {
      (<any>window)['googleSDKLoaded'] = () => {
      (<any>window)['gapi'].load('auth2', () => {
        this.auth2 = (<any>window)['gapi'].auth2.init({
          client_id: this.GoogleAppKey,
          plugin_name: 'login',
          cookiepolicy: 'single_host_origin',
          scope: '',
          ux_mode: 'popup'
        });
        this.callLogin();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement('script');
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs?.parentNode?.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }



}

